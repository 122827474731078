import '@shared/styles/index.scss';
import '@shared/styles/vendors/gradient.scss';
import '@shared/styles/vendors/highlight.scss';
import '@shared/styles/vendors/table.scss';
import '@shared/styles/vendors/wpforms.scss';

import { useMemo } from 'react';
import cn from 'classnames';
import type { AppProps } from 'next/app';

import { Noto_Sans, Poppins } from 'next/font/google';
import Algolia from '@/utilities/contexts/Algolia';
import CountryCode from '@/utilities/contexts/CountryContext';
import Domain from '@/utilities/contexts/Domain';
import Locale from '@/utilities/contexts/Locale';
import Nonce from '@/utilities/contexts/Nonce';
import PageLoader from '@/components/atoms/PageLoader';
import SegmentProvider from '@/utilities/contexts/segment/SegmentProvider';
import Website, { useWebsite } from '@/utilities/contexts/Website';

// ... Shared package components
import { CriticalBannerCookie } from '@shared/utilities/contexts/CriticalBanner';
import { NonCriticalBannerCookie } from '@shared/utilities/contexts/NonCriticalBanner';
import { typeWebsite } from '@shared/utilities/types/Website';

const notosans = Noto_Sans({
  style: ['normal', 'italic'],
  subsets: ['latin'],
  variable: '--noto-sans',
  weight: ['400', '500', '700'],
  display: 'swap',
});

const poppins = Poppins({
  style: ['normal'],
  subsets: ['latin'],
  variable: '--poppins',
  weight: ['700'],
  display: 'swap',
});

const App = (props: AppProps) => {
  const { Component, pageProps, router } = props;

  return (
    <CountryCode>
      <Domain>
        <Website>
          <Locale>
            <WebsiteWrapper Component={Component} pageProps={pageProps}  router={router}/>
          </Locale>
        </Website>
      </Domain>
    </CountryCode>
  );
};

const WebsiteWrapper = ({ Component, pageProps }: AppProps) => {
  const website = useWebsite();
  const memoizedWebsite = useMemo(() => website, [website]);
  
  return (
    <CriticalBannerCookie website={memoizedWebsite as typeWebsite}>
      <NonCriticalBannerCookie website={memoizedWebsite as typeWebsite}>
        <Algolia>
          <SegmentProvider>
            <div className={cn(notosans.variable, poppins.variable)}>
              <Nonce>
                <PageLoader />
                <Component {...pageProps} />
              </Nonce>
            </div>
          </SegmentProvider>
        </Algolia>
      </NonCriticalBannerCookie>
    </CriticalBannerCookie>
  );
};

export default App;